module services {
    'use strict';
    export module logistics {
        export class CommercialInvoiceSkuService implements interfaces.logistics.ICommercialInvoiceSkuService {
            static $inject = ['$resource', 'ENV'];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {

            }

            getDetail(): ng.resource.IResourceClass<interfaces.logistics.ICommercialInvoiceSKU> {
                return this.$resource<interfaces.logistics.ICommercialInvoiceSKU>(this.ENV.DSP_URL + "CommercialInvoiceSku/GetDetail", {
                    cisId: '@cisId',
                    comId: '@comId',
                    cntId: '@cntId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            params: {
                                cisId: 0
                            },
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceSku/Save");
            }
           
            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceSku/Delete", {
                    commercialInvoiceLineId: '@commercialInvoiceLineId'
                });
            }

            getSKUDefaults(): ng.resource.IResourceClass<interfaces.master.ISKU> {

                return this.$resource<interfaces.master.ISKU>(this.ENV.DSP_URL + "CommercialInvoiceSku/GetSKUDefaults", {
                    skuId: "@skuId",
                    cisId: '@cisId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }

            getUserAuthorisationDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CommercialInvoiceSku/GetUserAuthorisationDropdownList", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }


            ValidateUser(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceSku/ValidateUser");
            }

        }
    }
    angular.module("app").service("commercialInvoiceSkuService", services.logistics.CommercialInvoiceSkuService);
} 